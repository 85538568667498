/* 
 * app.js
 * 
 * @copyright      Copyright 2015, AceWare, Inc. (http://www.aceware.co.jp)
 */

var app = angular.module('app', ['ui.bootstrap', 'duScroll', 'ngMessages', 'blockUI', 'ngAnimate', "tc.chartjs", 'dndLists']);

app.config(['$httpProvider', function ($httpProvider) {
    // XHRリクエストヘッダーをセット
    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
}]);

app.config(['blockUIConfig', function (blockUIConfig) {
    blockUIConfig.autoBlock = false;
    blockUIConfig.message = '読み込み中です。しばらくお待ちください。';
    blockUIConfig.template = '<div class="block-ui-overlay"></div><div class="loader-container"><div class="loader"></div><div class="loader-message">{{ state.message }}</div></div>';
}]);

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push(function ($q) {
        return {
            /*
            'response': function (response) {
                //Will only be called for HTTP up to 300
                ////console.log(response);
                return response;
            },
            */
            'responseError': function (rejection) {
                if(rejection.status === 401) {
                    location.reload();
                }
                return $q.reject(rejection);
            }
        };
    });
}]);

app.constant('$helper', {

    characterSet: {
        alpha:        '英小文字',
        alphaUpper:   '英大文字',
        num:          '半角数字',
        numzen:       '全角数字',
        symbol:       '半角記号',
        symbolZen:    '全角記号',
        hira:         'ひらがな',
        kana:         '全角カタカナ',
        hankana:      '半角カタカナ'
    },

    getCharacterSet: function(exclusion) {

        var characterSet = {};

        angular.forEach(this.characterSet, function(label, key) {
            if (exclusion.indexOf(key) == -1) {
                this[key] = label;
            }
        }, characterSet);

        return characterSet;
    },

    serialNumberSet: {
        '%d':  '1(頭に0を付けない)',
        '%0d': '001(頭に0を付ける)'
    },

    charset: {
        'iso-2022-jp':  'ISO-2022-JP',
        'utf-8':        'UTF-8',
        'shift_jis':     'Shift JIS'
    },

    encode: {
        '7bit':             '7Bit',
        '8bit':             '8Bit',
        'base64':           'Base64',
        'quoted-printable': 'Quoted-printable'
    },

    setDefault: function(source, defaultObject) {
        const helper = this;

        function mergeObject(source, defaultObject) {
            angular.forEach(source, function(value, key) {
                if (value === null || value === undefined) {
                } else if (typeof value === 'string' && value.trim() === "") {
                    defaultObject[key] = value;
                } else if (Array.isArray(value)) {
                    if (value.length) {
                        defaultObject[key] = value;
                    }
                } else if (typeof value === 'object') {
                    defaultObject[key] = mergeObject(value, defaultObject[key]);
                } else if (typeof value === 'string' && helper.isNumber(value)) {
                    defaultObject[key] = parseInt(value, 10);
                } else {
                    defaultObject[key] = value;
                }
            });
            return defaultObject;
        }

        return mergeObject(source, defaultObject);
    },
    
    calcSubnetMask: function(ip) {
        var data = {
            ip: {},
            mask: null,
            start: {},
            end: {},
            class: null
        };
        
        var ipmask = ip.split("/");
        data.ip.text = ipmask[0];
        if(isFinite(ipmask[1]) && 1 <= parseInt(ipmask[1], 10) <= 32) {
            data.mask = ipmask[1];
        }
        
        data.ip.bin = data.ip.text.split('.').map(function(x) {
            // 数値型に変換
            x = parseInt(x, 10);
            // 2進数に変換
            x = x.toString(2);
            // 8bitになるようにゼロパディング
            return ( '00000000' + x ).slice( -8 );
        }).join("");
        data.ip.dec = parseInt(data.ip.bin, 2);
        
        // クラスを特定
        if(data.ip.bin.match(/^0/)) {
            data.class = "A";
        } else if(data.ip.bin.match(/^10/)) {
            data.class = "B";
        } else if(data.ip.bin.match(/^110/)) {
            data.class = "C";
        } else if(data.ip.bin.match(/^1110/)) {
            data.class = "D";
        } else if(data.ip.bin.match(/^1111/)) {
            data.class = "E";
        }
        
        if(data.mask === null || data.mask == 32) {
            return data;
        }
        
        // ネットワーク部のみ取得
        var network = data.ip.bin.substr(0, data.mask);
        // ホスト部のbit数を取得
        var hostBit = 32 - data.mask;
        // ネットワークアドレス(開始IP)
        data.start.bin = network + Array(hostBit + 1).join("0");
        data.start.dec = parseInt(data.start.bin, 2);
        // ブロードキャストアドレス(終了IP)
        data.end.bin = network + Array(hostBit + 1).join("1");
        data.end.dec = parseInt(data.end.bin, 2);
        
        // 10進数に変換
        data.start.text = data.start.bin.split(/([01]{8})/).filter(Boolean).map(function(x) {
            return parseInt(x, 2);
        }).join(".");
        
        // 10進数に変換
        data.end.text = data.end.bin.split(/([01]{8})/).filter(Boolean).map(function(x) {
            return parseInt(x, 2);
        }).join('.');
        
        return data;
    },
    
    isFormError: function(form, name, validation) {
        if(!angular.isUndefined(name) && typeof name === "string") {
            if(!form[name]) {
                return true;
            }
        } else {
            return form.$invalid;
        }
        
        if(!angular.isUndefined(validation) && typeof validation === "string") {
            return (form[name].$dirty && form[name].$error[validation]);
        } else {
            return (form[name].$invalid && form[name].$dirty);
        }
    },
    
    arrayGet: function(obj, key, defaults) {
        if(typeof obj !== "object" || Object.keys(obj).length === 0 || obj === null) {
            return defaults;
        }
        
        var keys = [];
        if(typeof key === "string") {
            // 「.」を含む場合
            if(key.indexOf('.') !== -1) {
                keys = key.split('.');
            } else {
                return (typeof obj[key] !== 'undefined') ? obj[key] : defaults;
            }
        } else if(angular.isArray(key)) {
            keys = angular.copy(key);
        }
        
        return this.arrayGet(obj[keys.shift()], keys.join("."), defaults);
    },

    range: function(min, max, step) {
        if(angular.isUndefined(step)) {
            step = 1;
        } else if(typeof step !== "number") {
            step = Number(min);
        }

        if(angular.isUndefined(min) || angular.isUndefined(max)) {
            return [];
        }

        if(typeof min !== "number") {
            min = Number(min);
        }

        if(typeof max !== "number") {
            max = Number(max);
        }

        if(isNaN(min) || isNaN(max)) {
            return [];
        }

        if(max < step) {
            step = max;
        }

        return Array.from({length: (max - min + step) / step}, (v, k) => min + (k * step));
    },

    count: function(data) {
        if(Array.isArray(data) === true) {
            return data.length;
        } else {
            return Object.keys(data).length;
        }
    },

    isNumber: function(value) {
        const type = typeof(value);

        // 数字か
        if(type === "number" && Number.isFinite(value)) {
            return true;
        }
        // 文字列型の数字か
        else if(
            type === "string" &&
            value.trim() === value &&
            value !== "" &&
            Number.isFinite(value - 0)
        ) {
            return true;
        }

        return false;
    }
});

app.filter('timeFormat', function() {
    return function(value)
    {
        var string = "";
        var hour = value / 3600 | 0;
        var min  = value % 3600 / 60 | 0;
        var sec  = value % 60;

        if (hour !== 0) {
            string = string+hour+"時間";
        }

        if (min !== 0) {
            string = string+min+"分";
        }

        if (sec !== 0) {
            string = string+sec+"秒";
        }

        return string;
    }
});

app.filter('limit', function () {
    return function (array, start, end) {
        return array.slice(start, end);
    };
});

app.filter('toArray', function() { return function(obj) {
    return _.toArray(obj);
};});

app.filter('trueLength', function() {
    return function (object) {
        var trueKeys = [];
        angular.forEach(object, function (value, key) {
            if (value === true) {
                this.push(key);
            }
        }, trueKeys);

        return trueKeys.length;
    };
});

app.filter('exclude', function () {
    return function (objects, conditions) {
        var result = {};

        // オブジェクトと文字列で分ける


        angular.forEach(objects, function (objectValue, objectKey) {
            var keyCheck = false;
            var valueCheck = false;

            // key check
            if (conditions.key) {
                if (typeof conditions.key === 'object') {
                    angular.forEach(conditions.key, function (key) {
                        if (key !== objectKey) {
                            keyCheck = true;
                        }
                    });
                } else if (conditions.key !== objectKey) {
                    keyCheck = true;
                }
            }

            // value check
            if (conditions.value) {
                if (typeof conditions.value === 'object') {
                    angular.forEach(conditions.value, function (value, key) {
                        if (value !== objectValue[key]) {
                            valueCheck = true;
                        }
                    });
                } else if (conditions.value !== objectValue) {
                    valueCheck = true;
                }
            }

            if (conditions.key && conditions.value && keyCheck && valueCheck) {
                this[objectKey] = objectValue;
            } else if (conditions.key && !conditions.value && keyCheck) {
                this[objectKey] = objectValue;
            } else if (!conditions.key && conditions.value && valueCheck) {
                this[objectKey] = objectValue;
            }


        }, result);

        return result;
    };
});

app.filter('newLineMark', function () {
    return function (text) {
        // 改行の前に改行マーク(↵)を挿入
        return text.replaceAll(/(\r\n|\r|\n)/g, "\u21B5$&");
    };
});

app.filter('andSearchFilter', function(){
    /**
     * 検索対象がキーワードと一致するかどうか判定
     * 
     * @param {array|object|string} data 検索対象
     * @param {string} keyword 検索ワード
     * @param {string} key 特定のプロパティを検索
     * @param {boolean} compare 完全一致で検索するか
     * @returns {Boolean}
     */
    function keywordJudge(data, keyword, key, compare) {
        if(angular.isArray(data)) {
            // 格納されている要素を順番にチェックし、ひとつでも条件に一致した場合trueを返す
            return data.some(function (child) {
                return keywordJudge(child, keyword, key, compare);
            });
        } else if(angular.isObject(data)) {
            // 子要素を順番にチェックし、ひとつでも条件に一致した場合trueを返す
            var properties = Object.keys(data);
            return properties.some(function (prop) {
                var child = null;
                if(!key || (key && (key === prop || key === "$"))) {
                    child = data[prop];
                }
                return keywordJudge(child, keyword, key, compare);
            });
        } else if(data !== null) {
            // 条件に一致した場合trueを返す
            return (compare && data === keyword) || (!compare && data.toString(10).indexOf(keyword) > -1);
        }
        
        return false;
    }
    
    /**
     * 文字列をパターンごとに配列に変換し、重複した値を削除した配列を返す
     * 
     * @param {string} string
     * @param {string|regular expression} separator 区切り文字
     * @returns {array}
     */
    function uniqueArraySplit(string, separator) {
        // 前後の空白を削除してパターンごとに配列に変換
        var array = string.replace(/^\s+|\s+$/g,'').split(separator);
        // 重複した値を削除
        return array.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        });
    }
    
    return function(array, exp, compare, not){
        if(typeof compare !== "boolean") {
            compare = false;
        }
        
        // 否定条件「!」を適用するか
        if(typeof not !== "boolean") {
            not = true;
        }
        
        var search = {};
        var result = [];
        if(exp && array) {
            if(typeof exp === "string") {
                search['$'] = uniqueArraySplit(exp, /\s+/);
            } else if(angular.isArray(exp)) {
                search['$'] = exp;
            } else if(angular.isObject(exp)) {
                angular.forEach(exp, function(value, prop) {
                    if(typeof value === "string") {
                        this[prop] = uniqueArraySplit(value, /\s+/);
                    } else if(angular.isArray(value)) {
                        this[prop] = value;
                    }
                }, search);
            }
            
            angular.forEach(array, function(value) {
                var properties = Object.keys(search);
                var isMatch = properties.some(function(prop) {
                    var keywords = search[prop];
                    return keywords.some(function(keyword) {
                        if(typeof keyword === "string" && keyword.charAt(0) === "!" && not) {
                            return !keywordJudge(value, keyword.substring(1), prop, compare);
                        } else {
                            return keywordJudge(value, keyword, prop, compare);
                        }
                    });
                });
                
                if(isMatch) {
                    this.push(value);
                }
            }, result);
        } else {
            result = array;
        }
        
        return result;
    };
});

app.directive('fileModel', ['$parse', '$timeout', function ($parse, $timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;

            element.bind('change', function () {
                $timeout(function(){
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]);

app.directive('onFinishRender', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit('ngRepeatFinished');
                });
            }
        }
    }
});


/**
 * バリデーション
 */

/**
 * IPアドレス
 */
var IP_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
app.directive('ip', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.ip = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (IP_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
        }
    };
});

//var DOMAIN_REGEXP = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
var DOMAIN_REGEXP = /^(?!:\/\/)(([a-zA-Z0-9_-]+\.)*)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]+?$/i;
app.directive('domain', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.domain = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (DOMAIN_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
        }
    };
});

app.directive('ipordomain', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.ipordomain = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (IP_REGEXP.test(viewValue)) {
                    return true;
                }
                if (DOMAIN_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
        }
    };
});


// IPアドレスのチェックと末尾に任意で「:ポート番号」があるか
var IPPLUSPORT_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d+)?$/;
// ドメインのチェックと末尾に任意で「:ポート番号」があるか
var DOMAINPLUSPORT_REGEXP = /^(?!:\/\/)(([a-zA-Z0-9_-]+\.)*)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]+?(:\d+)?$/i;

 app.directive('ipordomainplusport', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.ipordomainplusport = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (IPPLUSPORT_REGEXP.test(viewValue)) {
                    return true;
                }
                if (DOMAINPLUSPORT_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
        }
    };
});

app.directive('textareaDomains', [function() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.textareaDomains = function(modelValue, viewValue){
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                var data_array = viewValue.split(/\r\n|\r|\n/);
                var len = data_array.length;

                for (var i = 0; i < len; i++) {
                    if (!DOMAIN_REGEXP.test($data_array[i])) {
                        return false;
                    }
                }

                return true;
            };
        }
    };
}]);

app.directive('spf', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.spf = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (DOMAIN_REGEXP.test(viewValue)) {
                    return true;
                } else if (IP_REGEXP.test(viewValue)) {
                    return true;
                } else {
                    // ipとmaskに分割してみる
                    var textParts = viewValue.split('/');

                    if (textParts[1] && textParts[1].match(/[^0-9]+/)) {
                        return false;
                    }

                    if (IP_REGEXP.test(textParts[0]) && textParts[1] >= 1 && textParts[1] <= 32) {
                        return true;
                    }
                }

                return false;
            };
        }
    };
});

app.directive('ipmask', function ($helper) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.ipmask = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (IP_REGEXP.test(viewValue)) {
                    return true;
                } else {
                    // ipとmaskに分割してみる
                    var textParts = viewValue.split('/');
                    if (IP_REGEXP.test(textParts[0]) && textParts[1] >= 1 && textParts[1] <= 32) {
                        // サブネットの指定が正しいかチェック
                        var ipData = $helper.calcSubnetMask(viewValue);
                        if(ipData.ip.text !== ipData.start.text) {
                            return false;
                        }
                        
                        return true;
                    }
                }

                return false;
            };
        }
    };
});

app.directive('hostrange', function ($helper) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.hostrange = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                
                // 登録済みのIPと予約IPをまとめる
                var checkIps = scope.$eval(attrs.hostrange).concat([
                    // ループバック
                    "127.0.0.0/8",
                    // プライベートアドレス
                    "10.0.0.0/8",
                    "172.16.0.0/12",
                    "192.168.0.0/16",
                    // リンクローカルアドレス(APPIPA)
                    "169.254.0.0/16",
                    // 現在のネットワーク
                    "0.0.0.0/8"
                ]);
                
                // ipとmaskに分割してみる
                var textParts = viewValue.split('/');
                if (IP_REGEXP.test(viewValue) || (IP_REGEXP.test(textParts[0]) && textParts[1] >= 1 && textParts[1] <= 32)) {
                    var ipData = $helper.calcSubnetMask(viewValue);
                    var test = [];
                    angular.forEach(checkIps, function(checkIp) {
                        var checkIpData = $helper.calcSubnetMask(checkIp);
                        
                        if(viewValue === checkIp) {
                            test.push(checkIp);
                        } else if(ipData && checkIpData) {
                            if(ipData.start.bin && checkIpData.start.bin) {
                                if(checkIpData.start.dec < ipData.end.dec && checkIpData.end.dec > ipData.start.dec) {
                                    test.push(checkIp);
                                }
                            } else if(!ipData.start.bin && checkIpData.start.bin) {
                                if(checkIpData.start.dec < ipData.ip.dec && ipData.ip.dec < checkIpData.end.dec) {
                                    test.push(checkIp);
                                }
                            } else if(ipData.start.bin && !checkIpData.start.bin) {
                                if(ipData.start.dec < checkIpData.ip.dec && checkIpData.ip.dec < ipData.end.dec) {
                                    test.push(checkIp);
                                }
                            } else {
                                if(ipData.ip.text === checkIpData.ip.text) {
                                    test.push(checkIp);
                                }
                            }
                        }
                    }, test);
                    
                    if(test.length === 0) {
                        return true;
                    }
                }
                
                return false;
            };
        }
    };
});

app.directive('unique', function ($helper) {
    function search(searchValue, target, key, excludeKeys)
    {
        if(typeof searchValue === "string") {
            searchValue = searchValue.toLowerCase();
        }

        let excludeKey;
        if(angular.isArray(excludeKeys) === true && excludeKeys.length > 0) {
            excludeKey = excludeKeys.shift();
        }

        if(angular.isArray(target) === true) {
            return target.some(function(value, index) {
                if(excludeKey === index) {
                    return search(searchValue, value, key, excludeKeys);
                } else {
                    return search(searchValue, value, key);
                }
            });
        } else if(angular.isObject(target) === true) {
            if(key === "*" || key === "" || typeof key !== "string") {
                const targetKeys = Object.keys(target);

                return Object.values(target).some(function(value, index) {
                    if(excludeKey === targetKeys[index]) {
                        return search(searchValue, value, key, excludeKeys);
                    } else {
                        return search(searchValue, value, key);
                    }
                });
            }

            if(angular.isArray(excludeKeys) === true && angular.isUndefined(excludeKey) === false) {
                excludeKeys.unshift(excludeKey);
                excludeKey = excludeKeys.join(".");

                if(excludeKey === key) {
                    excludeKeys = [];
                }
            }

            target = $helper.arrayGet(target, key);
        }

        if(typeof target === "string") {
            target = target.toLowerCase();
        }

        if(angular.isArray(excludeKeys) === true && excludeKeys.length === 0 && searchValue === target) {
            return false;
        }

        return searchValue === target;
    }

    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.unique = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                let arg = scope.$eval(attrs["unique"]);
                if(angular.isUndefined(arg) === true) {
                    arg = attrs["unique"];
                }

                let target;
                let key;
                if(
                    typeof arg !== "object" ||
                    angular.isArray(arg) === true ||
                    (angular.isObject(arg) === true && Object.keys(arg).length > 1)
                ) {
                    target = arg;
                } else {
                    key = Object.keys(arg)[0];
                    target = Object.values(arg)[0];
                }

                let excludeKeys;
                if(
                    typeof arg === "object" &&
                    angular.isUndefined(attrs["ngModel"]) === false
                ) {
                    let unique;
                    if(angular.isUndefined(key) === false) {
                        const regexp = new RegExp(/^\{\s*[\"\']?[^\"\']+[\"\']\s*:\s*(.+)\s*?\}$/);
                        unique = $helper.arrayGet(attrs["unique"].match(regexp), "1", "");
                    } else {
                        unique = attrs["unique"];
                    }

                    // 同じ変数か
                    if(typeof unique === "string" && unique !== "" && attrs["ngModel"].indexOf(unique) === 0) {
                        // キーを取得
                        const keys = attrs["ngModel"].slice(unique.length).split(".");

                        excludeKeys = [];
                        angular.forEach(keys, function(value) {
                            // ブラケット記法か
                            if(value.match(/^\[[^\[\]]+\]$/) !== null) {
                                value = value.slice(1, -1);

                                // キーが変数で指定されているのであれば値を取得する
                                if(angular.isUndefined(scope.$eval(value)) === false) {
                                    value = scope.$eval(value);
                                }
                            }

                            if((typeof value === "string" && value !== "") || typeof value === "number") {
                                this.push(value);
                            }
                        }, excludeKeys);
                    }
                }

                if(search(modelValue, target, key, excludeKeys) === false) {
                    return true;
                }

                return false;
            };
        }
    };
});

app.directive('notIn', function() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.notIn = function (modelValue, viewValue) {
                if(ctrl.$isEmpty(modelValue)) {
                    return true;
                }

                let tabooWords = scope.$eval(attrs["notIn"]);
                if(angular.isUndefined(tabooWords) === true) {
                    tabooWords = attrs["notIn"];
                }

                return tabooWords.indexOf(modelValue) === -1;
            };
        }
    };
});

var ALPHA_DASH_REGEXP = /^([a-zA-Z0-9@._-])+$/;
app.directive('alphaDash', function() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.alphaDash = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (ALPHA_DASH_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
        }
    };
});

app.directive('number', function() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.number = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                if (!viewValue.match(/[^0-9]+/)) {

                    return true;
                }
                return false;
            };
        }
    };
});

app.directive('passwordMatch', [function () {
    return {
        restrict: 'A',
        scope:true,
        require: 'ngModel',
        link: function (scope, elem , attrs,ctrl) {
            var checker = function () {
                //get the value of the first password
                var e1 = scope.$eval(attrs.ngModel); 
                //get the value of the other password  
                var e2 = scope.$eval(attrs.passwordMatch);
                return e1 == e2;
            };
            scope.$watch(checker, function (n) {
 
                //set the form control to valid if both 
                //passwords are the same, else invalid
                ctrl.$setValidity("unique", n);
            });
        }
    };
}]);
